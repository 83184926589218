import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
    apiKey: "AIzaSyBGnP2Jo8_su7JtjFZ_s2ZFDBkHKttb8UQ",
    authDomain: "liikkuvaturku.firebaseapp.com",
    databaseURL: "https://liikkuvaturku.firebaseio.com",
    projectId: "liikkuvaturku",
    storageBucket: "liikkuvaturku.appspot.com",
    messagingSenderId: "972371168989",
    appId: "1:972371168989:web:70d98f2a330c87fc629534",
    measurementId: "G-0KVQCMHSGD"
};
// Initialize Firebase
firebase.initializeApp(config);

export default firebase;